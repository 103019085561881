.recentmain{
    padding: 30px;
    margin-top: 40px;
}
.cardtoppandit:hover{
    background-color:#fefafa;
}
.cardtoppandit{
    margin: 20px;
    border-left: 4px solid #022278;
    box-shadow: 1px 2px 2px 2px #f5f6f8;
}
.icotoppandit{
   border: 1px solid #000;
   height: 80px;
   width: 80px;
   margin: 20px;
}