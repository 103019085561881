.contactcard{
    margin: 17px;
    width: 80%;
    height: 100%;
    border-radius: 20px;
    box-shadow: 8px 5px 5px 6px #f5f6f8;
    border-bottom: 5px solid #feb92d ;
}
.contacticon{
    height: 70px;
    width: 70px;
    border-radius: 50%;
    background-color: #f3f3f4;
    margin-top: 30px;
    padding: 5px;
}