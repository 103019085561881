.stepsmain{
    background-color: #022278;
    border-radius: 35px;
    padding: 60px;

}
.pstep{
    color: #ffb600;
    font-weight: 800;
    text-align: center;

}

.stepico{
    background-color: #ffb600;
    height: 80px;
    width: 80px;
    border-radius: 50%;
    justify-content: center;
    justify-items: center;
    display: flex;
}