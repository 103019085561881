.imgpandit{
    height:330px;
    background-color:#022278;
    margin-left: 100px;
    border:8px solid #ffb600;
    transition: transform .5s;
    width: 330px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    
}

.imgpandit:hover{
    transform: scale(1.1);

}
