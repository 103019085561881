.staticmain{
margin-top: 50px;
        background-color: #022278;
        padding: 60px;
        border-radius: 35px;
}

.numberstatics{
    margin-top: 30px;
    padding: 2px;
    border-right: 1px solid #7748ee;

}