/* @import "slick-carousel/slick/slick.css";
@import "slick-carousel/slick/slick-theme.css"; */

.teztimonialcard{
    box-sizing: border-box;
    overflow: hidden;
    margin: 10px;
    width: 200px;
    height: 275px;
    border-radius: 20px;
    padding: 10px;
        /* background-color: #f2f4f8; */
    box-shadow: 2px 2px 2px 2px #383838;
   
}
.testicon{
    height: 80px;
    width: 80px;
    border-radius: 50%;
    background-color: #f3f3f4;
    margin-top: 10%;
    padding: 5px;
 
}
.cardtestcurv{
    width:100%;
    height: 100%;
    background-color:#feb92d;
    
}