
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400&display=swap');

h1{
    font-family: 'Poppins', sans-serif;
}

h2{
    font-family: 'Poppins', sans-serif;
}

h3{
    font-family: 'Poppins', sans-serif;
}

h4{
    font-family: 'Poppins', sans-serif;
}

h5{
    font-family: 'Poppins', sans-serif;
}

h6{
    font-family: 'Poppins', sans-serif;
}

p{
    font-family: 'Poppins', sans-serif;
}

.BgNavbar{
  background:  rgb(255, 255, 255)
  
}
.Header{

    font-family: 'Poppins', sans-serif;
    color:#6d74a5;
    font-weight: bold;

}
.titleNavbar{
    font-family: 'Poppins', sans-serif;
    color:#6d74a5;
    font-weight: bold;
    font-size: 13PX;
    text-decoration: none;
    margin: 8px;
    
}
.titleNavbar:hover{
    color: rgb(242, 156, 81);
}
@import url('https://fonts.googleapis.com/css2?family=DM+Sans&display=swap');
#upload_btn {
  box-sizing: border-box;
  margin: 0;
  min-width: 10%;
  height: 10%;
  display: inline-block;
  text-decoration: none;
  font-size: 10px;
  color: #fff;
  background-color:#2B97A4 ;
  border: 0;
  font-family: DM Sans;
  font-weight: 0;
  border-radius: 25px;
  cursor: pointer;
 text-align: center;
}

#upload_btn:hover {
background-color:#051646 ;
}
#upload_btn:active {
background-color: #020718;
}
#upload_btn:focus {
background-color: #020718;
outline: none !important;
box-shadow: none !important;
}

.navbar-light .navbar-nav .nav-link
{
  color: #323232 ;
}
.headerLink {
  font-family: DM Sans;
  font-size: 14px;
  color: #323232 !important;
  font-weight: 700;
  display: inline-block;
  position: relative;
  text-decoration: none;
  background-color: transparent;
  margin-left: -5px;
}
.headerLink:focus {

  font-family: DM Sans;
  font-size: 15px;
  color: #323232 !important;
  font-weight: 700;
  display: inline-block;
  position: relative;
  text-decoration: none;
  background-color: transparent;
}


.headerLink::after {
content: '';
display: block;
width: 0;
height: 2px;
background: #e45b06;
transition: width .3s;
}
.headerLink:hover::after {
width: 100%;

}

/* 
.headerLink.active {
border-bottom: 3px solid red;
} */



.headerLinkDrop {

  font-family: DM Sans;
  font-size: 16px;
  color: #323232 !important;
  display: inline-block;
  position: relative;
  text-decoration: none;
  background-color: transparent;
}


.headerLinkDrop::after {
content: '';
display: block;
width: 0;
height: 2px;
background: #88C417;
transition: width .3s;
}
.headerLinkDrop:hover::after {
width: 100%;

}

.headerLinkDrop.active {
border-bottom: 3px solid red;
}

.nav-link{
  color: #323232;
}

.nav-link:hover{
  color: #323232;
}


/* .dropdown-toggle::after {
  content: none;
} */

@media (max-width: 991px) {
  #navbarLine {
    display: none !important;
  }
}
@media (max-width: 991px) {
  .headerLink {
    font-family: DM Sans;
    font-size: 15px;
    color: #323232 !important;
    font-weight: 700;
    display: inline-block;
    ssssposition: relative;
    text-decoration: none;
    background-color: transparent;
    margin-left: 5px !important;;
  }
}

.nav-item.dropdown:hover .dropdown-menu {
  display: block;
}

/* .dropC {
  margin-left: -500px;
} */
/* .dropcc {
  margin-left: 50px;
} */

