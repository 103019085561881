body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.search_bar {
  border-radius: 100px;
  background: #fff8e7;
  width: 100%;
  margin-top: 2vmax;
  border-bottom: 3px solid #ffb600;

  input {
    background: transparent;
    width: 90%;
    border: none;
    outline: none;
  }
  button {
    background: #ffb600;
    border: none;
    outline: none;
    padding: 7px 15px;
    border-radius: 100px;
    font-weight: semibold;
  }
}

/* ======================== pooja_details_page ======================== */

.pooja_details_page {
  .pooja_details_page_col1 {
    svg {
      color: #ffb600;
      font-size: 20px;
    }
  }
  ul {
    li {
      list-style: inside !important;
    }
  }
  .badge_c{
    background: #ffd15c;
    padding: 5px 10px;
    border-radius: 100px;
    font-size: 12px;
  }

  button{
    background: #ffb600;
    border: none;
    outline: none;
    padding: 7px 15px;
    border-radius: 100px;
    font-weight: semibold;
  }
}
