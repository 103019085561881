.footermain {
  background-color: #f8f5ff;
  margin-top: 80px;
  padding: 50px;
}

.social-icon {
  height: 25px;
  width: 25px;
  margin-top: 30px;
  margin-left: 20px;
  transition: padding 0.3s ease;
  cursor: pointer;
}
