.categoryMain{
    padding: 30px;
    margin-top: 30px;
}

.popName{
    color:#022279;
    font-size: 33px;
    font-weight: 700;
}

.crdCategory{
    box-shadow: 5px 5px 5px 5px #f5f6f8;
    margin: 20px;
    display: flex;
    position: relative;
    height: 100px;
    width: 100%;
    overflow: hidden;
    background-color: #fff;
    border-radius: 20px;
    cursor: pointer;
}
.crdCategory:hover::before{
    content: '';
    position: absolute;
    width:100%;
    height: 70px;
    background: linear-gradient(#ffb600,#022279);
    animation: animate 3s linear infinite;
}

.crdCategory::after{
    content: '';
    position: absolute;
    inset: 4px;
    background: #fff;
}
@keyframes animate {
    0%
    {
     transform: rotate(10deg);
    }
    100%
    {
     transform: rotate(350deg);
    }
}
.divCard{
    background-color: #d5dce8;
    height: 60px;
    width: 60px;
    border-radius: 50%;
    margin-top: 20px;
    margin-left: 20px;
    position: relative;
    z-index: 10;
    display: flex;
    align-items: center;
    justify-content: center;
}
.textareas{
    display: flex;
    position: relative;
    z-index: 10;
}
