.roundhoroscoptext{
    position: relative;
}
.roundhoroscop{
    position: absolute;
    height: 270px;
    width: 270px;
    animation-name: circlemove;
    animation-duration: 10s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    transition: all .3s;
    margin-top: -100px;
    margin-left: -80px;
}
@keyframes circlemove {
   0% {
        transform: rotate(0deg);
    }
   100% {
        transform: rotate(360deg);
    }
   
}
